import { Text, View } from "@react-pdf/renderer";
import { styles } from "../style";

export function Footer(props) {
  const code = props.overrideCode || props.configObject.code || "";
  const ngayBanHanh = props.ngayBanHanh || "01/04/2019";
  return (
    <>
      <Text style={[styles.pageCode, styles.italic]} fixed>
        {code}
      </Text>
      <Text
        style={[styles.pageNumber, styles.italic]}
        render={({ pageNumber, totalPages }) =>
          `Trang ${pageNumber}/${totalPages}`
        }
        fixed
      />
      <Text style={[styles.pageEnd, styles.italic]} fixed>
        Ngày ban hành: Yên Bái {ngayBanHanh}
      </Text>
      {props.children}
    </>
  );
}

export function RenderSigns({ titles, values, bellow = true }) {
  return (
    <View style={[styles.row, styles.textCenter, { minHeight: "3cm" }]}>
      {(titles ?? []).map((text, index) => (
        <SignItem
          bellow={bellow}
          value={(values ?? [])[index]}
          key={index}
          flex={100 / titles.length}
        >
          {text}
        </SignItem>
      ))}
    </View>
  );
}

//signItem component
function SignItem({ flex, children, bellow, value }) {
  return (
    <View style={{ flex: `0 0 ${flex}%` }}>
      <Text style={styles.bold}>{children}</Text>
      {bellow && <Text>(Ký và ghi rõ họ tên)</Text>}
      {!!value && <Text style={{ paddingTop: "2cm" }}>{value}</Text>}
    </View>
  );
}
