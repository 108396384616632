import { Document, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { Footer, RenderSigns } from "../Common/Components/footer";
import PdfTable from "../Common/Components/pdfTable";
import { ReportName } from "../Common/Components/reportName";
import { TitleLeft, TitleRight } from "../Common/Components/title";
import { styles } from "../Common/style";
import { monthArr } from "../Common/utils";

export default function BienBanBanGiaoMauPhongKeHoach(props) {
  //Ngang
  const { currentDate, code, data, dicReports } = props;

  data &&
    data.forEach((element) => {
      element.soBienBan = dicReports[element.sampleId];
    });
  return (
    <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
      <Document>
        <Page size="A4" style={[styles.page]} orientation={"landscape"}>
          <View style={styles.section}>
            <View style={styles.row}>
              <View style={[styles.col_30, styles.textCenter]}>
                <TitleLeft isNotLogo={true} />
              </View>
              <View style={styles.col_20}></View>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleRight />
              </View>
            </View>
            <ReportName {...props} disableTop></ReportName>
            <Information1 {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <PdfTable {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <Information2 {...props} />
          </View>
          {/*  */}
          <Footer {...props} />
        </Page>
      </Document>
    </PDFViewer>
  );
}

function Information1(props) {
  const { data, currentDate } = props;
  //const info = data[0] || {};
  const samplers = [
    ...new Set(
      data
        .flatMap((x) => x.samplerInformation)
        .filter((x) => x?.samplerName)
        .map(
          (x) =>
            "Ông/bà: " +
            x.samplerName +
            ", chức vụ: " +
            (x?.samplerPosition ?? "..........")
        )
    ),
  ];

  return (
    <View>
      <Text>
        Hôm nay, vào lúc {currentDate?.getHours() ?? "....."} giờ{" "}
        {currentDate?.getMinutes() ?? "....."} phút, ngày{" "}
        {currentDate?.getDate() ?? "..."} tháng{" "}
        {monthArr[currentDate?.getMonth()] ?? "..."} năm{" "}
        {currentDate?.getFullYear() ?? "....."} . Tại Kho lưu mẫu - Trung tâm
        Kiểm nghiệm thuốc, mỹ phẩm, thực phẩm tỉnh Yên Bái.
      </Text>
      <Text>Chúng tôi gồm:</Text>
      {samplers.map((sampler, index) => {
        return (
          <Text>
            {index + 1}. {sampler}
          </Text>
        );
      })}

      <Text>
        Tiến hành bàn giao {data.length?.toString()?.padStart(2, "0")} mẫu thuốc
        để xác định chất lượng, cụ thể như sau:
      </Text>
    </View>
  );
}

function Information2(props) {
  const { data, currentSubStanceType, currentDate } = props;
  const info = data[0] || {};
  let signs = ["NGƯỜI NHẬN MẪU", "P.GIÁM ĐỐC", "NGƯỜI BÀN GIAO MẪU"];
  const nguoiDiLayMau = info?.samplerInformation[0]?.samplerName;
  const nguoiNhanMau = info?.custodianName;
  return (
    <>
      {/* footer info */}
      <View>
        <Text>
          Biên bản được lập xong lúc {currentDate?.getHours() ?? "....."} giờ{" "}
          {currentDate?.getMinutes() ?? "....."} phút, ngày{" "}
          {currentDate?.getDate() ?? "..."} tháng{" "}
          {monthArr[currentDate?.getMonth()] ?? "..."} năm{" "}
          {currentDate?.getFullYear() ?? "....."} .
        </Text>

        <Text>
          Biên bản bàn giao này được lập thành {info.si3 || "......"} bản có giá
          trị như nhau. Bên bàn giao giữ {info.si4 || "......"} bản và cán bộ
          tiếp nhận mẫu của phòng
        </Text>
        <Text>Kế hoạch giữ {info.si5 || "......"} bản.</Text>
      </View>
      {/* footer signs */}
      <RenderSigns titles={signs} values={[nguoiNhanMau, "", nguoiDiLayMau]} />
    </>
  );
}
